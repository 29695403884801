import * as React from "react";

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import useAuth, { User } from "../useAuth";

import { getAllCountries } from "../hooks/getAllCountries";
import { useState } from "react";

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $id: ID!
    $firstName: String!
    $email: String!
    $address1: String!
    $state: String!
    $postcode: String!
    $country: CountriesEnum
    $phone: String!
  ) {
    updateCustomer(
      input: {
        id: $id
        firstName: $firstName
        email: $email
        shipping: {
          address1: $address1
          state: $state
          postcode: $postcode
          country: $country
          phone: $phone
        }
      }
    ) {
      customer {
        firstName
        email
        id
        lastName
        shipping {
          address1
          address2
          state
          postcode
          country
          phone
        }
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($customerId: Int) {
    customer(customerId: $customerId) {
      firstName
      email
      shipping {
        address1
        state
        company
        postcode
        country
        phone
      }
      billing {
        state
      }
      firstName
      
    }
  }
`;

const PersonalInfoCard = (props: BoxProps) => {
  const toast = useToast();
  var allCountries = getAllCountries();

  const { user } = useAuth();
  const { id, firstName, lastName, email } = user as User;

  const [updateProfile, { data, loading, error }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: () => {
        toast({
          title: "Your profile was successfully updated!",
          status: "success",
        });
        window.location.reload();
      },
      onError: () => {
        toast({
          title: "Error",
          description: "There was an error updating you profile.",
          status: "error",
        });
      },
    }
  );

  const customer = useQuery(GET_CUSTOMER, {
    variables: { id },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    const values = Object.fromEntries(data);

    updateProfile({
      variables: { id, ...values },
    }).catch((error) => {
      console.error(error);
    });
  }

  const [selects, setSelects] = useState("");

  return (
    <Box
      id="personalInfoCard"
      maxW="680px"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      bg="white"
      {...props}
    >
      <form method="post" onSubmit={handleSubmit}>
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <FormControl id="firstName">
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                defaultValue={firstName || ""}
                placeholder={firstName || ""}
                name="firstName"
              />
            </FormControl>
            {/* <FormControl id="lastName">
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                defaultValue={lastName || ""}
                placeholder={lastName || ""}
                name="lastName"
              />
            </FormControl> */}
             <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              defaultValue={email || ""}
              placeholder={email || ""}
              name="email"
            />
          </FormControl>

          </Stack>
          <FormControl id="phone">
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="text"
              defaultValue={customer.data?.customer.shipping.phone || ""}
              placeholder={customer.data?.customer.shipping.phone || ""}
              name="phone"
            />
          </FormControl>

          <FormControl id="addressLine1">
            <FormLabel>Address Line</FormLabel>
            <Input
              type="text"
              defaultValue={customer.data?.customer.shipping.address1 || ""}
              placeholder={customer.data?.customer.shipping.address1 || ""}
              name="address1"
            />
          </FormControl>

          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            {/* <FormControl id="city">
              <FormLabel>City</FormLabel>
              <Input
                defaultValue={customer.data?.customer.shipping.city || ""}
                placeholder={customer.data?.customer.shipping.city || ""}
                name="city"
              />
            </FormControl> */}
            <FormControl id="state">
              <FormLabel>State / Province</FormLabel>
              <Input
                type="text"
                defaultValue={customer.data?.customer.shipping.state || ""}
                placeholder={customer.data?.customer.shipping.state || ""}
                name="state"
              />
            </FormControl>
            <FormControl id="zip">
              <FormLabel> ZIP </FormLabel>
              <Input
                name="postcode"
                defaultValue={customer.data?.customer.shipping.postcode || ""}
                placeholder={customer.data?.customer.shipping.postcode || ""}
              />
            </FormControl>
          </Stack>

          <FormControl id="country">
            <FormLabel>Current Country</FormLabel>
            <Input
              disabled={true}
              type="text"
              defaultValue={selects}
              placeholder={customer.data?.customer.shipping.country || ""}
              name="country"
            />
            </FormControl>
            <FormControl>
            <FormLabel>Change Country</FormLabel>
            <Select
              id="country"
              name="country"
              value={selects}
              onChange={(e) => setSelects(e.target.value)}
            >
              {allCountries.map((item: any) => {return <option >{ item.countryCode }</option>; })}
            </Select>
          </FormControl>
          <FormControl id="message">
              <FormLabel> Message </FormLabel>
              <Input
                name="message"
                type="textarea"
              />
            </FormControl>
        </Stack>
        {error ? <p className="error-message">{error.message}</p> : null}
        <Divider />
        <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
          <Button type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default PersonalInfoCard;
